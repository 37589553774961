import { Link as BaseLink, RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { GatsbyImageFluidProps } from 'gatsby-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Accordion, AccordionItem } from '../components/Accordion';
import { ListItem, Ol, Ul } from '../components/List';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1, Typography } from '../components/Typography';
import { Device } from '../utils/media-queries-utils';
import { Colors } from '../utils/style-utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 100px 220px;

  @media ${Device.laptopM} {
    padding: 100px 150px;
  }

  @media ${Device.laptop} {
    padding: 100px 75px;
  }

  @media ${Device.tablet} {
    padding: 100px 30px;
  }

  @media ${Device.mobileS} {
    padding: 100px 30px;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 12pt;
  line-height: 1.75;
  margin-bottom: 1rem;
`;

const Link = styled(BaseLink)`
  color: ${Colors.Blue500};
`;

const About: FC<RouteComponentProps> = (props) => {
  const staticData = useStaticQuery<{
    images: {
      edges: {
        node: {
          name: string;
          childImageSharp: GatsbyImageFluidProps;
        };
      }[];
    };
    faqsSection1: any;
    faqsSection2: any;
    faqsSection31: any;
    faqsSection32: any;
    faqsSection41: any;
    faqsSection42: any;
    faqsSection43: any;
    faqsSection44: any;
    faqsSection51: any;
    faqsSection52: any;
  }>(graphql`
    query {
      images: allFile(filter: { relativePath: { regex: "/(about)/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1000) {
                aspectRatio
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            name
          }
        }
      }
      faqsSection1: file(relativePath: { eq: "faqs-section-1.png" }) {
        childImageSharp {
          fixed(width: 548) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      faqsSection2: file(relativePath: { eq: "faqs-section-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection31: file(relativePath: { eq: "faqs-section-3-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection32: file(relativePath: { eq: "faqs-section-3-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection41: file(relativePath: { eq: "faqs-section-4-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection42: file(relativePath: { eq: "faqs-section-4-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection43: file(relativePath: { eq: "faqs-section-4-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection44: file(relativePath: { eq: "faqs-section-4-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection51: file(relativePath: { eq: "faqs-section-5-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      faqsSection52: file(relativePath: { eq: "faqs-section-5-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const step2Image = staticData.images.edges.find((img) => img.node.name === 'about-step-2')!;
  const step3Image = staticData.images.edges.find((img) => img.node.name === 'about-step-3')!;
  const step4Image = staticData.images.edges.find((img) => img.node.name === 'about-step-4')!;

  return (
    <PublicRoute {...props}>
      <SEO
        title="User Guide"
        description="An overview of how Hypercite works, what it's for and frequently asked questions about Hypercite and its usage"
      />
      <Container>
        <H1 className="mb-24" color="#051392">
          CALIFORNIA COURT OF APPEALS AND SUPREME COURT COMPREHENSIVE USER GUIDE
        </H1>

        <Paragraph>
          Hello, and let us introduce you to Hyper(Cite)<sup>&reg;</sup>.
        </Paragraph>

        <Paragraph>
          In the California Court of Appeal and Supreme Court, the Rules allow filers to hyperlinks any citations to the
          record on appeal. (Rule 8.74 (b)(5).)
        </Paragraph>

        <Paragraph>
          Until now, California filers have had to transform their textual citations to the record into hyperlinks by
          hand or employ third parties that charge exorbitant fees. However, neither method remains the status quo.
        </Paragraph>

        <Paragraph>
          Hyper(Cite)<sup>&reg;</sup> is a new, web-based software, that automatically creates hyperlinks between
          textual citations in a filer’s parent document (e.g. brief, petition, motion, or etc.) to the record on appeal
          or previous briefing. The record on appeal for Hyper(Cite)<sup>&reg;</sup> linking purposes does not only
          include an appendix or Clerk’s Transcript. Hyper(Cite)<sup>&reg;</sup> hyperlinks citations to reporter’s
          transcripts and previous briefing as well.
        </Paragraph>

        <Paragraph>
          Instructions, below, expand upon the information located on our home page. Please take the time to read these
          instructions to ensure the parent document ins linked as intended. If instructions are not followed, anomalous
          links can occur.
        </Paragraph>

        <Paragraph>
          There are five steps to Hyper(Cite)<sup>&reg;</sup> correctly in the instructions below. They are:
        </Paragraph>

        <Ol>
          <ListItem>Create a free account</ListItem>
          <ListItem>Upload your parent document file</ListItem>
          <ListItem>Upload your child document file(s)</ListItem>
          <ListItem>Tells us your citations convention(s)</ListItem>
          <ListItem>Submit and download for free</ListItem>
          <Ol className="pl-6" style={{ listStyle: 'inside lower-roman' }}>
            <ListItem>Submission</ListItem>
            <ListItem>How much does it cost?</ListItem>
            <ListItem>Error identification</ListItem>
            <ListItem>
              Ways Hyper(Cite)<sup>&reg;</sup> will benefit your practice and clients
            </ListItem>
          </Ol>
        </Ol>

        <Paragraph>
          We hope the following assists you on your Hyper(Cite)<sup>&reg;</sup> journey.
        </Paragraph>

        <Paragraph>Happy hyperlinking!</Paragraph>

        <Paragraph>
          -The Hyper(Cite)<sup>&reg;</sup> team.
        </Paragraph>

        <Accordion className="mb-12">
          <AccordionItem title="Step #1: Create a free account">
            <Typography className="pb-12">
              Please create a free account prior to utilizing Hyper(Cite)<sup>&reg;</sup>. Follow the on screen prompts
              to do so.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #2: Upload your parent document file">
            <Typography className="pb-12">
              The parent document file is the document that contains citations. This is commonly a brief, petition,
              motion, or etc. This is the parent document because citations contained within it refer to other documents
              (children). For example, a brief (parent) will contain citation to a reporter’s transcript (child).
            </Typography>

            <Typography className="pb-12">
              Clicking on “Add .PDF file” located underneath “Upload Parent Document File” on Hyper(Cite)’s
              <sup>&reg;</sup> home screen will cause a computer to enter its file browsing mode. Select the parent
              document from your computer which contains the citations to be linked.
            </Typography>

            <div className="relative pb-12">
              <Image fluid={step2Image.node.childImageSharp.fluid} />
            </div>

            <Typography className="pb-12">
              There is only one parent document. For those advanced users wishing to create a parent document that
              itself contains hyperlinked children, for example, a hyperlinked reply brief which citations to prior
              bring (a hyperlinked response and hyperlinked opening brief), this is accomplished by selecting a prior
              hyperlinked brief as a child document. In this manner, nested, multiple hyperlinked documents, are
              created.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #3: Upload your child document file(s)">
            <Typography className="pb-12">
              Child documents are the documents citations in the parent document refer to. Child documents may include,
              but are not limited to, an appendix, Clerk’s Transcript, reporter’s transcript, motion for judicial
              notice, prior briefing, other motions, writ exhibits, and etc. These are child documents because they are
              subordinate and referred to within the parent document.
            </Typography>

            <Typography className="pb-12">
              Clicking on “Add .PDF file” located underneath “Upload Child Document Files” on Hyper(Cite)’s
              <sup>&reg;</sup> home screen will cause a computer to enter its file browsing mode. Select any number of
              child documents your parent document refers to and would like to create hypelinks to from your computer
              for upload. Any number or conceivable.
            </Typography>

            <div className="relative pb-12">
              <Image fluid={step3Image.node.childImageSharp.fluid} />
            </div>

            <Typography className="pb-12">
              Importantly, Hyper(Cite)’s<sup>&reg;</sup> creates hyperlinks to electronic page numbers, not bates stamp
              numbers. Child documents must be consecutively numbered staring from page one. If a child document’s bates
              stamp numbers do not match a PDF program’s electronic numbers, please navigate <Link to="/">here</Link> to
              learn how to correct the issue.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #4: Tells us your citations convention(s)">
            <Typography className="pb-12">
              Citation conventions are the portion of a citation contained in a parent document that informs the court
              which child document the citation refers to. Citation conventions are usually acronyms. For example, in
              the citation (1 <b>C.T.</b> pp. 45-56; <b>RT</b> 55:16-17; <b>MJN</b> p. 12; <b>XYZ</b> Dec.) the citation
              conventions are C.T. RT MJN and XYZ Dec. That is because each of those citation conventions appears in
              every citation whenever one of those given child documents is cited. XYZ Dec. exhibits any citation
              conventions employed in a parent document may be utilized. They are fully customizable. Acronyms are not a
              requirement.
            </Typography>

            <Typography className="pb-12">
              After any number of child documents are uploaded, a citation convention input field will appear below each
              child document. This field is where the citation convention for each child document is placed.
            </Typography>

            <div className="relative pb-12">
              <Image fluid={step4Image.node.childImageSharp.fluid} />
            </div>

            <Typography className="pb-12">
              For writ exhibits, an additional box is checked which informs Hyper(Cite)<sup>&reg;</sup> to not treat
              exhibit number or letters as page numbers. For more information on writs, please navigate{' '}
              <Link to="/">here</Link>.
            </Typography>
          </AccordionItem>

          <AccordionItem title="Step #5:  Submit and download for free">
            <Ul style={{ listStyle: 'none' }}>
              <ListItem>
                <Typography className="font-bold pb-6">i. Submission</Typography>

                <Typography className="pb-12">
                  After a parent document and any number of child documents are selected for upload, and a citation
                  convention is inputted for each child document, hit the “upload” button for processing. Processing
                  times vary according to the size of each document but generally take under a minute. After processing,
                  the computer’s web browser should cause the document the now hyperlinked output to download. If not,
                  please click the link for manual download.
                </Typography>
              </ListItem>

              <ListItem>
                <Typography bold className="font-bold pb-6">
                  ii. How much does it cost?
                </Typography>

                <Typography className="pb-12">
                  Hyper(Cite)<sup>&reg;</sup> is currently free to use. We do not collect data or sell data to third
                  parties to obtain income. The software is free to use so that it gains wider adoption. Payment is a
                  barrier to entry currently.
                </Typography>
              </ListItem>

              <ListItem>
                <Typography bold className="font-bold pb-6">
                  iii. Error identification
                </Typography>

                <Typography className="pb-12">
                  Hyper(Cite)<sup>&reg;</sup> possesses an error identification mechanism. If a citation is made to a
                  page that does not exist, a gray box will appear around the subject citation in the hyperlinked
                  output. Please review the citation to correct the error and re-upload. Upon download, it is wise to
                  confirm each citation hyperlinks to the intended destination.
                </Typography>
              </ListItem>

              <ListItem>
                <Typography bold className="font-bold pb-6">
                  iv. Ways Hyper(Cite)<sup>&reg;</sup> will benefit your practice and clients
                </Typography>

                <Typography className="pb-12">
                  Hyperlinked documents greatly benefit an attorneys practice, clients, and the courts. Hyperlink your
                  own submissions to build credibility with the court, check your own citations much more readily, and
                  hyperlink the opposing party’s to check their citation much more readily as well. Also employ
                  Hyper(Cite)<sup>&reg;</sup> to link oral argument notes to the record as well.
                </Typography>
              </ListItem>
            </Ul>
          </AccordionItem>


        <H1 className="pt-24 my-24" color="#051392">
          FAQs
        </H1>
          <AccordionItem
            title={
              <span>
                What if I use line numbers in my citations in the document to be linked (brief, motion, petition, or
                etc.). Can HyperCite<sup>&reg;</sup> accommodate line numbers?
              </span>
            }
          >
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process documents to be linked that contain line numbers citations. However,
              the practitioner must follow some specific guidelines. These guidelines are in addition to guidelines
              specified in the first question of this FAQ.
            </Typography>

            <Typography className="pb-6">Anatomy of a simple citation containing line numbers:</Typography>

            <div className="flex items-center justify-center">
              <Image fixed={staticData.faqsSection1.childImageSharp.fixed} />
            </div>
          </AccordionItem>

          <AccordionItem
            title={
              <span>
                What if in the citations within the document to be linked (brief, motion, petition, or etc.) contain
                several different volumes or source documents. Can HyperCite
                <sup>&reg;</sup> accommodate those citation forms?
              </span>
            }
          >
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process documents to be linked that contain citations to multiple volumes or
              source documents. However, the practitioner must follow one guideline. A semicolon must be placed between
              different volumes or sources.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection2.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="Are there special rules for instances where I use an Exhibit or Tab number, like for a writ petition?">
            <Typography className="pb-12">
              HyperCite<sup>&reg;</sup> can process writ petitions, however, it is a circumstance that demands one extra
              step and adherence to some guidelines. When uploading a source document that contains Exhibit or Tab
              numbers, there is a check box on the HyperCite
              <sup>&reg;</sup> upload record source page that the practitioner must select. Moreover, the practitioner
              must keep some citation guidelines in mind.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection31.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection32.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              Although the above sample does not contain line numbers, line numbers may be employed with Exhibits or
              Tabs in accordance with Question #1 of this FAQ.
            </Typography>

            <Typography className="pb-12">
              Moreover, different volumes and source documents may be combined in the same string citation in accordance
              with Question #2 of this FAQ.
            </Typography>
          </AccordionItem>

          <AccordionItem title="What if my Clerk’s Transcript or Reporter’s Transcript bate stamp numbers do not match the Adobe system’s page numbers? (The Reporter or Clerk included unnumbered pages)">
            <Typography className="pb-12">
              In order for HyperCite<sup>&reg;</sup> to work properly, the page numbers displayed in the PDF viewer must
              match the bate stamp numbers of the reporter’s transcript, clerk’s transcript, or any other source
              document. The following illustrates when the PDF viewer and bates stamp numbers match each other
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection41.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              When HyperCite<sup>&reg;</sup> links citation page numbers to the appellate record, it utilizes the page
              number displayed by the PDF viewer, not the bates stamp number. When the page number displayed by the PDF
              viewer and bates stamp numbers are not the same, this will cause a problem. Citations citing to bates
              stamp page one when the numbers do not mate will not go to the proper page.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection42.childImageSharp.fluid} />
              </div>
            </div>

            <Typography className="pb-12">
              The reason the page numbers do not match in the above example is the court reporter left the title page
              and various tables unnumbered. The reporter started bate stamp page one after six unnumbered pages. A
              similar problem can arise when the Clerk in preparing the Clerk’s Transcript decides to un-number the
              table of contents and title page. In order for HyperCite<sup>&reg;</sup> to work properly, the page
              numbers must be synced. The following illustrates how to sync the PDF viewer page number and bates number.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection43.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection44.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>

          <AccordionItem title="What if my Clerk’s Transcript or Reporter’s Transcript bate stamp numbers do not match the Adobe system’s page numbers? (The Reporter’s or Clerk’s transcripts do not start at page 1)">
            <Typography className="pb-12">
              Sometimes, a Reporter or Clerk will start each date of testimony or volume at a page other than one. For
              example, your first Reporter’s Transcript starts at page 100. If your Reporter’s Transcript starts at
              bates stamp 100, Adobe will read that page as page one. HyperCite<sup>&reg;</sup> will link a citation to
              page 100 in the brief to page one. This can be fixed with page labels.
            </Typography>

            <div className="flex items-center justify-center pb-12">
              <div className="w-full">
                <Image fluid={staticData.faqsSection51.childImageSharp.fluid} />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="w-full">
                <Image fluid={staticData.faqsSection52.childImageSharp.fluid} />
              </div>
            </div>
          </AccordionItem>
        </Accordion>
      </Container>
    </PublicRoute>
  );
};

export default About;
